import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  /**
   * UTILS
   */

  getDateWithCurrentTime(date: Date): Date {
    const now = DateTime.local();
    const newDate = DateTime.fromJSDate(date).set({
      hour: now.hour,
      minute: now.minute,
    });
    return newDate.toJSDate();
  }
}
