import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReloadService {
  private refreshData = new Subject<void>();
  private refreshFavorite = new Subject<void>();
  private refreshCart = new BehaviorSubject<boolean>(false);
  private refreshSearch = new BehaviorSubject<boolean>(false);
  /**
   * REFRESH GLOBAL DATA
   */
  get refreshData$() {
    return this.refreshData;
  }
  needRefreshData$() {
    this.refreshData.next();
  }

  /**
   * REFRESH GLOBAL DATA
   */
  get refreshFavorite$() {
    return this.refreshFavorite;
  }
  needRefreshFavorite$() {
    this.refreshFavorite$.next();
  }

  needRefreshSearch$(data?: boolean) {
    if (data) {
      this.refreshSearch.next(data);
    } else {
      this.refreshSearch.next(false);
    }
  }
  /**
   * CART
   */

  needRefreshCart$(data?: boolean) {
    if (data) {
      this.refreshCart.next(data);
    } else {
      this.refreshCart.next(false);
    }
  }
}
