import { Injectable } from '@angular/core';
import { Favorite } from 'interfaces/common';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Resp } from 'interfaces/core';

const API_URL = environment.apiBaseLink + '/api/favorite/';

@Injectable({ providedIn: 'root' })
export class FavoriteService {
  /**
   * REFRESH LOCAL STORED CART
   */

  constructor(private httpClient: HttpClient) {}

  /**
   * addToFavorite
   * getFavoriteByUserId
   * deleteFavoriteById
   */

  addToFavorite(advertId: string) {
    return this.httpClient.post<Resp>(
      API_URL + `add-to-favorite/${advertId}`,
      {}
    );
  }

  getFavoriteByUser() {
    return this.httpClient.get<Resp<Array<Favorite>>>(
      API_URL + 'get-favorite-by-user'
    );
  }

  deleteFavoriteById(id: string) {
    return this.httpClient.delete<Resp>(API_URL + 'delete/' + id);
  }
}
