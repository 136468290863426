import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from 'interfaces/common';
import { Resp } from 'interfaces/core';

const API_URL = environment.apiBaseLink + '/api/user/';

@Injectable({ providedIn: 'root' })
export class UserDataService {
  constructor(private httpClient: HttpClient) {}

  /**
   * getLoggedInUserData()
   * updateLoggedInUserInfo()
   * changeLoggedInUserPassword()
   * checkUserAndSentOtp()
   * checkEmailAndSentOtp()
   */

  getLoggedInUserData() {
    return this.httpClient.get<Resp<User>>(API_URL + 'logged-in-user-data');
  }

  updateLoggedInUserInfo() {
    return this.httpClient.put<Resp>(API_URL + 'update-logged-in-user', {});
  }

  changeLoggedInUserPassword(data: { password: string; oldPassword: string }) {
    return this.httpClient.put<Resp>(
      API_URL + 'change-logged-in-user-password',
      data
    );
  }

  checkUserAndSentOtp(data: { email: string }) {
    return this.httpClient.post<Resp>(
      API_URL + 'check-user-and-sent-otp',
      data
    );
  }

  checkEmailAndSentOtp(data: { email: string; newEmail: string }) {
    return this.httpClient.post<Resp>(
      API_URL + 'check-new-email-and-sent-otp',
      data
    );
  }
}
