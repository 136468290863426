import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarNotificationComponent } from 'shared/components/snackbar-notification/snackbar-notification.component';

type Key = 'success' | 'warn' | 'wrong';

@Injectable({ providedIn: 'root' })
export class UiService {
  constructor(public snackBar: MatSnackBar) {}

  private config(key: Key, msg: string): MatSnackBarConfig {
    return {
      data: msg,
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['notification', key + '-snackbar'],
    };
  }

  success(msg = 'Success') {
    this.snackBar.openFromComponent(
      SnackbarNotificationComponent,
      this.config('success', msg)
    );
  }

  warn(msg: string) {
    this.snackBar.openFromComponent(
      SnackbarNotificationComponent,
      this.config('warn', msg)
    );
  }

  wrong(msg: string) {
    this.snackBar.openFromComponent(
      SnackbarNotificationComponent,
      this.config('wrong', msg)
    );
  }
}
