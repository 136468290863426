import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Zone } from 'interfaces/common';
import { Resp } from 'interfaces/core';

const API_URL = environment.apiBaseLink + '/api/zone/';

@Injectable({ providedIn: 'root' })
export class ZoneService {
  constructor(private httpClient: HttpClient) {}

  /**
   * getZoneByParentId()
   */

  getZoneByParentId(divisionId: string, select: string) {
    const params = new HttpParams().set('select', select);

    return this.httpClient.get<Resp<Array<Zone>>>(
      API_URL + 'get-all-by-parent/' + divisionId,
      { params }
    );
  }
}
