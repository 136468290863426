import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Inject,
  Injectable,
  PLATFORM_ID,
  afterNextRender,
} from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, tap } from 'rxjs';
import { Language } from 'interfaces/common';

@Injectable({ providedIn: 'root' })
export class TranslationService {
  private readonly LANG_KEY = 'app-lang';
  private defaultLang: Language = 'da';
  private paramLang = this.defaultLang;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    afterNextRender(() => this.initializeLanguage());
    this.initializeLanguage();

    this.router.events.subscribe(event => {
      const urlTree = this.router.parseUrl(this.router.url);

      this.paramLang = urlTree.queryParams['lang'] || this.defaultLang;

      if (event instanceof NavigationStart) {
        const eventUrlTree = this.router.parseUrl(event.url);
        if (
          !eventUrlTree.queryParams['lang'] &&
          this.paramLang !== this.defaultLang
        ) {
          eventUrlTree.queryParams['lang'] = this.paramLang;
          this.router.navigateByUrl(eventUrlTree.toString(), {
            replaceUrl: true,
          });
        }
      }
    });

    this.route.queryParams.subscribe(params => {
      this.paramLang = params['lang'] || this.defaultLang;

      if (!params['lang'] && this.paramLang !== this.defaultLang) {
        this.router.navigate([], {
          queryParams: { lang: this.paramLang },
          queryParamsHandling: 'merge',
        });
      } else if (
        params['lang'] === this.defaultLang &&
        this.paramLang === this.defaultLang
      ) {
        this.router.navigate([], {
          queryParams: { lang: null },
          queryParamsHandling: 'merge',
        });
      }
    });
  }

  private initializeLanguage(): void {
    if (!isPlatformBrowser(this.platformId))
      return this.useLanguage(this.paramLang);

    const storedLang =
      this.paramLang || localStorage.getItem(this.LANG_KEY) || this.defaultLang;
    if (storedLang !== this.translate.currentLang) {
      this.useLanguage(storedLang);
    }
  }

  public loadTranslations(lang: Language): Observable<object> {
    return this.http.get(`/assets/i18n/${lang}.json`);
  }

  public useLanguage(lang: Language): void {
    this.loadTranslations(lang)
      .pipe(
        tap(translations => {
          this.translate.setTranslation(lang, translations);
          this.translate.use(lang);
          this.setLanguageInStorage(lang);
        })
      )
      .subscribe();
  }

  private setLanguageInStorage(lang: Language): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.LANG_KEY, lang);
    }
  }

  public getCurrentLanguage(): Language {
    return this.paramLang || this.defaultLang;
  }
}
