import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Advert } from 'interfaces/common';
import { Resp, FilterData } from 'interfaces/core';

const API_URL = environment.apiBaseLink + '/api/advert/';

@Injectable({ providedIn: 'root' })
export class AdvertService {
  constructor(private httpClient: HttpClient) {}

  addAdvertByUser(data: Advert) {
    return this.httpClient.post<Resp>(API_URL + 'add-by-user', data);
  }

  getAllAdverts(filterData: FilterData, searchQuery?: string) {
    let params = new HttpParams();
    if (searchQuery) {
      params = params.append('q', searchQuery);
    }
    return this.httpClient.post<Resp<Array<Advert>> & { count: number }>(
      API_URL + 'get-all',
      filterData,
      { params }
    );
  }

  getAllCombinedAdverts(filterData: FilterData, searchQuery?: string) {
    let params = new HttpParams();
    if (searchQuery && searchQuery.trim()) {
      params = params.set('q', searchQuery);
    }
    return this.httpClient.post<Resp<Array<Advert>> & { count: number }>(
      API_URL + 'get-all-combined',
      filterData,
      { params }
    );
  }

  getAdvertByUser() {
    return this.httpClient.get<Resp<Advert>>(
      API_URL + 'get-single-advert-user'
    );
  }

  getAdvertByShortId(shortId: number) {
    return this.httpClient.get<Resp<Advert>>(
      API_URL + 'get-by-short-id/' + shortId
    );
  }

  updateUserAdvertById(shortId: number, data: Advert) {
    return this.httpClient.put<Resp>(
      API_URL + 'update-by-user/' + shortId,
      data
    );
  }

  toggleAdvertStatus() {
    return this.httpClient.put<Resp>(API_URL + 'toggle-advert-status', {});
  }

  bumpAdvertById(id: string) {
    return this.httpClient.put<Resp>(API_URL + 'bump-by-user/' + id, {});
  }
}
