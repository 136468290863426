import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Report } from 'interfaces/common';
import { Resp } from 'interfaces/core';

const API_URL = environment.apiBaseLink + '/api/report/';

@Injectable({ providedIn: 'root' })
export class ReportService {
  constructor(private httpClient: HttpClient) {}

  /**
   * addReport
   */

  addReport(data: Report) {
    return this.httpClient.post<Resp>(API_URL + 'add', data);
  }
}
