import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Type } from 'interfaces/common';
import { Resp, FilterData } from 'interfaces/core';

const API_URL = environment.apiBaseLink + '/api/type/';

@Injectable({ providedIn: 'root' })
export class TypeService {
  constructor(private httpClient: HttpClient) {}

  getAllType(filterData: FilterData) {
    return this.httpClient.post<Resp<Type[]>>(API_URL + 'get-all/', filterData);
  }
}
