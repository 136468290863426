import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { UserService, HeaderService } from 'services/common';
import { UiService } from 'services/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  /* HEADER FIXED */
  public headerFixed = false;
  public isCookieBannerVisible = true;
  /**
   * Show and hide responsive nav.
   * Get subject data.
   */
  public showHideResponsiveNav = false;
  public changeColor = false;
  public isLoggedIn = false;

  constructor(
    private _headerService: HeaderService,
    public dialog: MatDialog,
    public router: Router,
    public uiService: UiService,
    private userService: UserService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  private get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    this.checkCookieBannerVisibility();

    this._headerService.headerColorChange.subscribe(res => {
      this.changeColor = res;
    });
  }

  /**
   * RESPONSIVE NAV
   * HEADER FIXED
   */
  public onShowHideResponsiveNav() {
    this.showHideResponsiveNav = !this.showHideResponsiveNav;
  }

  @HostListener('window:scroll')
  scrollBody() {
    this.headerFixed = window.scrollY > 50;
  }

  public hideCookies() {
    this.isCookieBannerVisible = false;
    if (this.isBrowser) {
      localStorage.setItem('lastDialogShown', Date.now().toString());
    }
  }

  private checkCookieBannerVisibility() {
    const MILLISECONDS_IN_MONTH = 30 * 24 * 60 * 60 * 1000;
    if (this.isBrowser) {
      const lastDialogShown = localStorage.getItem('lastDialogShown');
      this.isCookieBannerVisible =
        !lastDialogShown ||
        Date.now() - parseInt(lastDialogShown, 10) > MILLISECONDS_IN_MONTH;
    }
  }

  public onLogout() {
    this.userService.userLogOut();
    this.onShowHideResponsiveNav();
    window.location.reload();
  }
}
